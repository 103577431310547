import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import { LanguageData} from '../Translations/InterfaceTypes';


interface ExportCSVProps {
  languageData: LanguageData; // Pass your languageData as a prop
  language: string;
}

const ExportCSV: React.FC<ExportCSVProps> = ({ languageData, language }) => {
  const jsonToCsv = (val: string): string => {
    const csvValue = `"${val}"`; // Enclose value in double quotes
    return csvValue;
  };
 console.log(languageData)
  const handleCSVExport = () => {
    const languages = Object.keys(languageData);
    const headers = ["#", "Developer string", language]; // Original languages array
    const normalizedHeaders = headers.map((header, index) => {
      const normalizedHeader = jsonToCsv(header); // Remove commas from header
      return normalizedHeader;
    });

    const rows: any[] = [normalizedHeaders];
    const uniqueTexts = Array.from(
      new Set(
        Object.values(languageData[language]).map((item) => ({
          text: jsonToCsv(item.text),
          trans: jsonToCsv(item.translation),
          id:item.id
        }))
      )
    );


    uniqueTexts.forEach((text, rowIndex) => {
      const row: any[] = [];
      // const translationId = Object.keys(languageData[language]).find(
      //   (id) => jsonToCsv(languageData[language][id].text) === text
      // );

      // if (translationId) {
      //  const cellData = languageData[language][translationId].translation;
      //  if (cellData === "") {
          row.push(text.id); // Index
          row.push(text.text); // Developer string
          row.push(text.trans || '');
          rows.push(row);
      //  }
      // } else {
      //   row.push(""); // Leave other cells empty
      // }
    });

   const csvContent = rows
   .map((row) => {
     return row
       .map((cell:any) =>
         `"${String(cell).replace(/"/g, '')}"` // Wrap in quotes and escape internal quotes
       )
       .join(","); // Join cells with commas
   })
   .join("\n"); // Join rows with newlines

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "translations.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
    
        <div className="col-lg-9 align-self-center table-action-icons">
          <div className="float-end w-auto">
            <button
              onClick={handleCSVExport}
              className="border-0 bg-transparent px-0 download-csv d-flex align-items-center color-dark-purple"
              title={"Export CSV"}
            >
              <span>
                {"Export"}
                {":"}
              </span>
              <FontAwesomeIcon icon={faFileExport} className="fa-lg ms-2" />
            </button>
          </div>
        </div>
     
    </>
  );
};

export default ExportCSV;
