import React,{useState,useEffect,useRef} from 'react'
import ManageTranslationsTable from '../Molecules/ManageTranslationsTable';
import CustomNotify from "../../atoms/CustomNotify";
import secureLocalStorage from "react-secure-storage";
import { useQuery, useQueryClient,useMutation } from '@tanstack/react-query';

import {Translation,LanguageData,AllLinkedData,Language,SearchProps} from '../InterfaceTypes';
import {
  LOCALE_TARGETS,
  SAVE_TRANSLATION,
  UPDATE_TRANSLATIONS,
} from "../../../routes/ApiEndpoints";
import {
  MYMMO_MOBILE_APP,
  MYMMO_WEB,
  MYMMO_SERVICE_MICROSERVICE,
  MAX_FILE_SIZE,

} from "../../../Constants";
import { ApiCall } from "../../../services/ApiServices";



const  ManageTranslations = () =>{
    const queryClient = useQueryClient();
  const [searchData, setSearchData] = useState<SearchProps>({
    trans: "",
    appName: MYMMO_MOBILE_APP,
  });
  const [allLinkedData,setAllLinkedData] = useState<AllLinkedData>({});
  const [languageLinkedData, setLanguageLinkedData] = useState<LanguageData>({});
  const [languageDropDown, setLanguageDropDown] = useState<Language[]>();
  const [language, setLanguage] = useState("EN");
  const lang_key = secureLocalStorage.getItem("site_lang") ?? "en";
  const isInitialRender = useRef(true);
  const getLocalTargetAPI = async () => {
    const requestData = {
         appName: searchData.appName,
         langKey: language.toLocaleLowerCase(),
       };
    const response = await ApiCall.service(
      LOCALE_TARGETS,
      "POST",
      requestData,
      true,
      MYMMO_SERVICE_MICROSERVICE
    );
    return response;
  }

  const { data, isLoading, isError, error, refetch } = useQuery({
  queryKey: ['trans'],
  queryFn: getLocalTargetAPI,
  staleTime: 10000,  // Set stale time (100 seconds)
  gcTime: 10000,  // Set cache time (100 seconds)
  refetchOnWindowFocus: false, // Disable refetch on window focus
});





useEffect(() => {
  if (isInitialRender.current) {
    // Skip the first render
    isInitialRender.current = false;
    return;
  }

  // Invalidate queries based on language or appName change
  queryClient.invalidateQueries({ queryKey: ['trans'] });
}, [language, searchData.appName]);
console.log(isLoading,data)
useEffect(() => {
  // Only run this when data is available and not loading
  if (!isLoading && data?.status === 200) {
    console.log('useEffect triggered');
    const { languages, translations } = data.data;
    const initialLanguageData: LanguageData = {};
    setLanguageDropDown(languages);
    setLanguage(Object.keys(translations)[0]);
    secureLocalStorage.setItem('site_lang',Object.keys(translations)[0]);
    Object.keys(translations).forEach((languageKey) => {
      initialLanguageData[languageKey] = {};
      Object.keys(translations[languageKey]).forEach((translationKey) => {
        initialLanguageData[languageKey][translationKey] = {
          ...translations[languageKey][translationKey],
          isEditing: false, // Initialize the isEditing state
        };
      });
    });

    setAllLinkedData(initialLanguageData);
    setLanguageLinkedData(initialLanguageData);
  }
}, [data, isLoading]);
const handleEditClick = async (data:any) => {
  const {key,type} = data;
  if (type === "save") {
    if (languageLinkedData[language][key].translation === "") {

      CustomNotify({
        type: "warning",
        message: "Please provide translation",
      });
      return;
    }
    const response = await ApiCall.service(
      SAVE_TRANSLATION,
      "POST",
      {
        translation: languageLinkedData[language][key].translation,
        key: key,
        appName:searchData.appName ,
      },
      true,
      MYMMO_SERVICE_MICROSERVICE
    );

    if (response.status === 200) {
      CustomNotify({
        type: "success",
        message: response.message || response.msg,
      });
    } else {
      CustomNotify({
        type: "error",
        message: response.message || response.msg,
      });
    }
  }
  setLanguageLinkedData((prevLanguageLinkedData) => {
    const newLanguageData = {
      ...prevLanguageLinkedData,
      [language]: {
        ...prevLanguageLinkedData[language],
        [key]: {
          ...prevLanguageLinkedData[language][key],
          isEditing: !prevLanguageLinkedData[language][key].isEditing,
        },
      },
    };

    return newLanguageData;
  });
 };
 const handleEditClickmutate = useMutation({
   mutationFn: handleEditClick,
   onMutate: async (data) => {
     // Cancel any outgoing refetches (to avoid data inconsistency during optimistic update)
     await queryClient.cancelQueries({ queryKey: ['trans'] });
   },
   // onSettled: () => {
   //   // Refetch users list to ensure data consistency with the server
   //   queryClient.invalidateQueries(['users']);
   // },
 })


   const updateTranslations = async (langKey: string, missingTrans: Translation[]): Promise<void> => {
    console.log(languageLinkedData[langKey]);
    let response = await ApiCall.service(
      UPDATE_TRANSLATIONS,
      "POST",
      {
        translations: languageLinkedData[langKey],
        newTranslations:missingTrans,
        appName:searchData.appName,
      },
      true,
      MYMMO_SERVICE_MICROSERVICE
    );
    if (response.status === 200) {
      queryClient.invalidateQueries({ queryKey: ['trans'] });
      CustomNotify({
        type: "success",
        message: response.message || response.msg,
      });
     // setKey((prevKey) => prevKey + 1); // Update the key to force re-render
    }
   }
  return (
    <>
  {!isLoading &&  <ManageTranslationsTable
      languageLinkedData         = {languageLinkedData}
      setLanguageLinkedData      = {setLanguageLinkedData}
      handleEditClick            = {handleEditClickmutate.mutate}
      getLocalTarget             = {getLocalTargetAPI}
      updateTranslations         = {updateTranslations}
      language                   = {language}
      searchData                 = {searchData}
      setSearchData              = {setSearchData}
      setLanguage                = {setLanguage}
      allLinkedData              = {allLinkedData}

    />}
    </>
  )
}

export default ManageTranslations;
